import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import pic1 from "../images/01.png"
import axios from 'axios';
export default function Header() {

    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState(' 0612-2201849');
    const [userEmail, setUserEmail] = useState('');
    const [selected_category, setSelectedCategory] = useState('');
    const [selected_sub_category, setSelectedSubCategory] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [testSeries, setTestSeries] = React.useState([]);
    const [searchname, setSearchName] = React.useState('');



    useEffect(() => {
        // console.log("whehgdeudewud",token)
        // const fetchUserProfile = async () => {
        //   try {
        //     const authToken = localStorage.getItem('token'); 
        //    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/profile`, {
        //        // const response = await fetch(`http://192.168.0.102:1038/user/profile`, {   
        //         headers: {
        //           'Authorization': `Bearer ${authToken}` // Include token in headers for authorization
        //         }
        //       });
        //       console.log(response);
        //     if (!response.ok) {
        //       throw new Error('Failed to fetch user profile');
        //     }
        //     const userData = await response.json();
        //     const { name,phone,email,selected_category,selected_sub_category } = userData.user;

        //     setUserName(name);
        //     setUserPhone(phone);
        //     setUserEmail(email);
        //     setSelectedCategory(selected_category.name);
        //     setSelectedSubCategory(selected_sub_category.name);
        //    // setIsVerified(userData.user.is_otp_verify); 
        //     setIsLoading(false);
        //   } catch (error) {
        //     setError(error.message);
        //     setIsLoading(false);
        //   }
        // };

        // fetchUserProfile();
    }, []);


    const fetchTestSeries = async () => {
        try {
            window.location.href = `/searchtestseries/${searchname}`;
        } catch (error) {

            setIsLoading(false);
        }
    };





    const handleLogout = () => {
        localStorage.removeItem('token');
        // Redirect the user
        window.location.href = '/';
    };

    //<!-------------offerpagenotfound
    const fetchOffers = async () => {
        try {
            window.location.href = `/searchtestseries/${searchname}`;
        } catch (error) {

            setIsLoading(false);
        }
    };

    //   if (isLoading) {
    //     return <p>Loading...</p>;
    //   }

    //   if (error) {
    //     return <p>Error: {error}</p>;
    //   }
    return (
        <>
            <section className='menutop'>
                <div className='container-fluid'>
                    <div className='row justify-content-center' style={{ alignItems: "center" }}>
                        <div className='col-md-5'>
                            <div className='row justify-content-center'>
                                <div className='col-md-5 col-12' style={{ paddingLeft: "10px", paddingRight: "0px" }}>
                                    <center><p><i className="fa-solid fa-phone" /> :  {userPhone}</p></center>
                                    {/* <center><p><i className="" />  {selected_category} {selected_sub_category}</p></center> */}
                                </div>
                                <div className='col-md-7 col-12' style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                                    <center><p><i className="fa-solid fa-envelope" /> : info@pensbook.com  </p></center>
                                </div>



                                {/* {isVerified && ( // Render userName if user is verified
                        <div className='col-md-5 col-12' style={{ paddingLeft: "10px", paddingRight: "0px" }}>
                        <center><p><i className="" /> {userName}</p></center>
                        </div>
                    )} */}


                            </div>
                        </div>



                        <div className='col-md-2 col-4'>
                            <center><img src={pic1} className="Apogo" alt="logo" /></center>
                        </div>
                        <div className='col-md-5'>
                            <div className='row justify-content-center'>
                                <div className='col-md-6 col-12'>
                                    <div className="position-relative">
                                        {token && (
                                            <input className="form-control search-input mt-2" onChange={(e) => {
                                                setSearchName(e.target.value)
                                            }} type="search" placeholder="Search" aria-label="Search" style={{ borderRadius: "20px" }}
                                            />




                                        )}
                                        {token && (<i className="bi bi-search search-icon" />)}

                                    </div>
                                </div>
                                {/* <div className='col-md-3 col-7 ii'>
                                    <center><p className='btn btn-danger btn-block mt-2' style={{ borderRadius: "20px", fontSize: 12, color: "#fff" }}>GET STARTED</p></center>
                                </div> */}
                                <div className='col-md-3 col-7 ii'>
                                    <center>
                                        {!token && (
                                            <NavLink to="/login">
                                                <p className='btn btn-danger btn-block mt-2' style={{ borderRadius: "20px", fontSize: 12, color: "#fff" }}>GET STARTED</p>
                                            </NavLink>

                                        )}

                                        {token && (

                                            <button
                                                type="button"
                                                onClick={fetchTestSeries}
                                                className="btn btn-danger btn-block mt-2"
                                                style={{
                                                    borderRadius: "20px",
                                                    fontSize: 12,
                                                    color: "#fff",
                                                    fontFamily: "Arial, sans-serif", // Add a standard font
                                                }}
                                            >
                                                SEARCH
                                            </button>


                                        )}

                                    </center>
                                </div>
                                <div className='col-md-3 col-3'>
                                    {/* <center><img src="02.png" className="App-logo" alt="logo" width="100%" /></center> */}
                                    <center><button
                                        className="btn btn-danger btn-block mt-2"
                                        style={{
                                            borderRadius: "20px",
                                            fontSize: 12,
                                            color: "#fff",
                                        }}
                                        onClick={fetchOffers}
                                    >
                                        OFFERS
                                    </button></center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sticky-top'>
                <div className='container'>
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                        ><span className="navbar-toggler-icon" /></button>
                        <i className="wew text-white fa-brands fa-facebook" />
                        <i className="wew text-white fa-brands fa-twitter" />
                        <i className="wew text-white fa-brands fa-instagram" />
                        <i className="wew text-white fa-brands fa-linkedin" />
                        <i className="wew text-white fa-brands fa-youtube" />
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active vv">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} exact href="/"> Home <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} exact href="/exams">Exams</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/testseries">Test Series</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/perdayJanakari">Jankari</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/testseriesall">Exam</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/blog">Blogs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} exact href="/about">More</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/">Help</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/contact">Contact Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/resume">Resume</a>
                                </li>
                                <li className="nav-item">
                                    {
                                        !token && (
                                            <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/login">Login</a>
                                        )
                                    }
                                    {token && (<a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/profile">Profile</a>)}
                                    {/* { if(token) {
                                    
                                }else{
                                    <a className="nav-link text-left" style={{ paddingLeft: "10px" }} href="/profile">Profile</a>
                                } } */}

                                </li>
                            </ul>
                            <a href="https://www.facebook.com/profile.php?id=61569450830806 " target="_blank" rel="noopener noreferrer">
                                <i className="ewe text-white fa-brands fa-facebook" />
                            </a>
                            <a href="https://x.com/pensbook1" target="_blank" rel="noopener noreferrer">
                                <i className="ewe text-white fa-brands fa-twitter" />
                            </a>
                            <a href="https://www.instagram.com/pensbook01/?igsh=MXM0Ynd4Y3pjYnlrNg%3D%3D#" target="_blank" rel="noopener noreferrer">
                                <i className="ewe text-white fa-brands fa-instagram" />
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                <i className="ewe text-white fa-brands fa-linkedin" />
                            </a>
                            <a href=" https://www.youtube.com/@Pensbook" target="_blank" rel="noopener noreferrer">
                                <i className="ewe text-white fa-brands fa-youtube" />
                            </a>
                            <a href="https://t.me/+6pihPvT5fdBkZjc9" target="_blank" rel="noopener noreferrer">
                                <i className="ewe text-white fa-brands fa-telegram" />
                            </a>


                            {!token && (<a className="nav-link text-left" href="/login" style={{ color: "#fff", background: "#e40612", textAlign: "center", borderRadius: "40px", paddingLeft: "10px", fontWeight: "600" }}>Subscription</a>)}
                            {token && (<button className="nav-link text-left" onClick={handleLogout} style={{ color: "#fff", background: "#e40612", textAlign: "center", borderRadius: "40px", paddingLeft: "10px", fontWeight: "600" }} >Logout</button>)}
                        </div>
                    </nav>
                </div>
            </section>
        </>
    )
}